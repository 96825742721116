import React from "react"
import styled from 'styled-components'

//Helpers
import Title from "../helpers/Title"
import Video from "../helpers/Video"

//Components
import Layout from "../components/Layout"
import Seo from "../components/seo"

const Videos = () => (
  <Layout>
    <Seo title="Videos"/>
    <VideosContainer>
      <Title>Videos</Title>
      <Video id="AhCBlNCChsk" />
      <Video id="h2JK18vSUb4" />
      <Video id="aCMTW1ANgM8" />
      <Video id="KI5_OLniv2A" />
      <Video id="QAUkSdr_f9c" />
      <Video id="jf94K-EgCaA" />
    </VideosContainer>
  </Layout>
)

export default Videos

const VideosContainer = styled.section`
  padding-top: 2rem;
  margin-bottom: 4rem;
`