import React from "react"
import styled from 'styled-components'

const Video = ({ id }) => (
  <VideoContainer>
    <VideoBox>
      <VideoIFrame
        title="Youtube"
        aria-hidden="true"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/${id}/?autoplay=1><img src=https://img.youtube.com/vi/${id}/hqdefault.jpg alt='Video The Dark Knight Rises: What Went Wrong? – Wisecrack Edition'><span>▶</span></a>`}
      ></VideoIFrame>
    </VideoBox>
  </VideoContainer>
)

export default Video

const VideoContainer = styled.div`
  width: 100%;
  max-width: 800px;
  height: auto;
  margin: 0 auto;
  padding: 2rem 1rem;
`
const VideoBox = styled.div`
  background-color: #000;
	position: relative;
	padding-top: 56.25%;
	overflow: hidden;
	cursor: pointer;
  box-shadow: .25rem .25rem 2rem var(--black);
`

const VideoIFrame = styled.iframe`
  height: 100%;
	width: 100%;
  position: absolute;
	top: 0;
	left: 0;
`